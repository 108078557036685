@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Montserrat', 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.table-container {
  position: relative;
  width: 300px;
  /* Ajusta el ancho según tus necesidades */
  height: 300px;
  /* Ajusta la altura según tus necesidades */
  overflow: auto;
}

.table-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* Evita que la sombra interfiera con el contenido */
  transition: box-shadow 0.3s ease;
  /* Agrega una transición suave */
}

/*-----------------------------------------------------------*/
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  border: 2px solid #000000da;
  /* display: none; */
}

::-webkit-scrollbar-track {
  display: flex;
  background: #fff;
  /* border-top: 4px solid #FBD6C5;
  border-left: 4px solid #FBD6C5; */

}

::-webkit-scrollbar-thumb {
  background-color: var(--neutralhover, #A7B1BF);
  border-radius: 20px;
  border: 6px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  border: 5px solid #fff;
}

::-webkit-scrollbar-corner {
  background: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --colorPrimary-Orange: #F07B43;
  --coloPrimary-Black: #232323;
  --colorPrimary-Gray: #88919E;
  --colorHover-Orange: #FFFAF7;
  --colorActivo-Orange: #FFF5F0;
  --colorBorder-Orange: #F5916C;
  --colorPrimary-Hover: #F5A458;
  --colorPrimary-Error: #FF655A;
  --colorGrisClaro: #E2E5E8;
  --colorSecundaryOrange: #F49D73;
}